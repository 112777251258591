export const genres = [
  "Thriller - Crime",
  "Thriller - Mystery",
  "Fantasy - Magic",
  "Fantasy - Adventure",
  "Non-Fiction - Biography",
  "Non-Fiction - Science",
  "Fiction - Romance",
  "Fiction - Historical",
  "Fiction - Fantasy",
  "Science Fiction - Dystopian",
  "Science Fiction - Space",
  // 他のジャンルとサブジャンルを追加
];

export const readingLevels = [
  "Beginner - CEFR A1",
  "Beginner - Eiken Grade 5",
  "Beginner - TOEIC 120-225",
  "Intermediate - CEFR A2",
  "Intermediate - CEFR B1",
  "Intermediate - Eiken Grade 3",
  "Intermediate - TOEIC 500-595",
  "Advanced - CEFR B1",
  "Advanced - Eiken Grade 1",
  "Advanced - TOEFL iBT 80-94",
  // 他の読みレベルを追加
];

export const wordCounts = [
  "0-500 words",
  "500-1000 words",
  "1000-1500 words",
  "1500-2000 words",
  "2000-3000 words",
  // 他の文字数範囲を追加
];
