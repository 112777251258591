// src/components/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <section>
        <h2>1. Collection of Personal Information</h2>
        <p>We appropriately manage personal information provided by users and do not disclose it to third parties.</p>
      </section>
      <section>
        <h2>2. Purpose of Use of Personal Information</h2>
        <p>We use the collected personal information for the following purposes:</p>
        <ul>
          <li>To provide and operate the service</li>
          <li>To respond to inquiries from users</li>
          {/* Add other purposes as needed */}
        </ul>
      </section>
      {/* Add other sections as needed */}
    </div>
  );
}

export default PrivacyPolicy;
