import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './styles/global.css';
import { auth } from './firebase';
import Header from './components/Header';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import Login from './components/Login';
import Register from './components/Register';
import Library from './components/Library';
import ContentDetail from './components/ContentDetail';
import UserProfile from './components/UserProfile';
import AddContent from './components/AddContent'; // 新しく追加するコンポーネント
import TermsOfService from './components/TermsOfService'; // Import TermsOfService component
import PrivacyPolicy from './components/PrivacyPolicy'; // Import PrivacyPolicy component
import ReadingLog from './components/ReadingLog';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // ローディング状態を追加

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false); // 認証状態の確認が完了したらローディングを終了
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // ローディング中はローディング画面を表示
  }

  return (
    <div className="App">
      <Header user={user} />
      <div className="container">
        <Routes>
          <Route path="/" element={user ? <Library /> : <LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/library" element={user ? <Library /> : <Navigate to="/login" />} />
          <Route path="/content/:id" element={user ? <ContentDetail /> : <Navigate to="/login" />} />
          <Route path="/profile" element={user ? <UserProfile /> : <Navigate to="/login" />} />
          <Route path="/add-content" element={user ? <AddContent /> : <Navigate to="/login" />} /> {/* 新しいルート */}
          <Route path="/terms-of-service" element={<TermsOfService />} /> {/* Add TermsOfService route */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add PrivacyPolicy route */}
          <Route path="/reading-log" element={user ? <ReadingLog /> : <Navigate to="/login" />} /> {/* Add ReadingLog route */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
