import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Library.css';
import SearchBar from './SearchBar';

function Library() {
  const [contents, setContents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [level, setLevel] = useState('');
  const [genre, setGenre] = useState('');
  const [wordCount, setWordCount] = useState('');
  const [filteredContents, setFilteredContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'contents'));
      const contentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setContents(contentList);
      setFilteredContents(contentList);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const results = contents.filter(content => {
      const matchesSearchTerm = content.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                content.summary.toLowerCase().includes(searchTerm.toLowerCase());
      console.log('matchesSearchTerm:', matchesSearchTerm);

      const matchesGenre = genre ? `${content.genre} - ${content.subGenre}` === genre : true;
      console.log('matchesGenre:', matchesGenre);

      let matchesLevel = true;
      if (level) {
        const levelParts = level.split(' - ');
        const levelCategory = levelParts[0];
        const levelValue = levelParts[1] ? levelParts[1].split(' ')[1] : '';

        console.log('levelCategory:', levelCategory);
        console.log('levelValue:', levelValue);

        matchesLevel = content.level === levelCategory && 
          Object.values(content.readingLevels).flat().includes(levelValue);

        console.log('content.level:', content.level);
        console.log('content.readingLevels:', content.readingLevels);
        console.log('matchesLevel:', matchesLevel);
      }

      const matchesWordCount = wordCount ? (
        wordCount === '0-500 words' ? content.totalWordCount <= 500 :
        wordCount === '500-1000 words' ? content.totalWordCount > 500 && content.totalWordCount <= 1000 :
        wordCount === '1000-1500 words' ? content.totalWordCount > 1000 && content.totalWordCount <= 1500 :
        wordCount === '1500-2000 words' ? content.totalWordCount > 1500 && content.totalWordCount <= 2000 :
        wordCount === '2000-3000 words' ? content.totalWordCount > 2000 && content.totalWordCount <= 3000 :
        true
      ) : true;
      console.log('matchesWordCount:', matchesWordCount);

      return matchesSearchTerm && matchesGenre && matchesLevel && matchesWordCount;
    });
    setFilteredContents(results);
  }, [searchTerm, level, genre, wordCount, contents]);

  return (
    <div className="library-container">
      <h2>Welcome to your Library</h2>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        genre={genre}
        setGenre={setGenre}
        readingLevel={level}
        setReadingLevel={setLevel}
        wordCount={wordCount}
        setWordCount={setWordCount}
      />
      <div className="content-grid">
        {filteredContents.map((content) => (
          <div key={content.id} className="content-card">
            <Link to={`/content/${content.id}`}>
              <img src={content.imageUrl} alt={content.title} className="content-image" />
              <div className="content-details">
                <h3>{content.title}</h3>
                <p>{content.summary}</p>
                <p><strong>Author:</strong> {content.author}</p>
                <p><strong>Level:</strong> {content.level}</p>
                <p><strong>Genre:</strong> {content.genre} - {content.subGenre}</p>
                <p><strong>Word Count:</strong> {content.totalWordCount}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Library;
