import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import './UserProfile.css';
import ReadingLog from './ReadingLog';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const [completedContents, setCompletedContents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async (user) => {
      if (user) {
        console.log('User state changed:', user.uid);
        const userDocRef = doc(db, 'users', user.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            console.log('Document data:', docSnap.data());
            setUserData(docSnap.data());
            fetchCompletedContents(docSnap.data().completedContents);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      } else {
        console.log('No user is logged in');
        navigate('/login');
      }
    };

    const fetchCompletedContents = async (contentIds) => {
      if (contentIds.length === 0) return;

      const batchSize = 10;
      let contents = [];
      for (let i = 0; i < contentIds.length; i += batchSize) {
        const batchIds = contentIds.slice(i, i + batchSize).map(String); // 数値を文字列に変換
        const contentsQuery = query(
          collection(db, 'contents'),
          where('__name__', 'in', batchIds)
        );
        try {
          const querySnapshot = await getDocs(contentsQuery);
          contents = [...contents, ...querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))];
        } catch (error) {
          console.error('Error fetching completed contents:', error);
        }
      }
      setCompletedContents(contents);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      fetchUserData(user);
    });

    return () => unsubscribe();
  }, [navigate]);

  const getTodayWordCount = () => {
    if (!userData || !userData.dailyTotalWordCount) return 0;
    const today = new Date().toISOString().split('T')[0].replace(/-/g, '_');
    return userData.dailyTotalWordCount[today] || 0;
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile-container">
      <h2>{userData.username}'s Profile</h2>
      <p><strong>Total Word Count:</strong> {userData.totalWordCount}</p>
      <p><strong>Today's Total Word Count:</strong> {getTodayWordCount()}</p>
      <p><strong>Weekly Total Word Count:</strong> {Object.values(userData.weeklyTotalWordCount || {}).reduce((a, b) => a + b, 0)}</p>
      <p><strong>Monthly Total Word Count:</strong> {Object.values(userData.monthlyTotalWordCount || {}).reduce((a, b) => a + b, 0)}</p>
      <ReadingLog />
      <p><strong>Completed Contents:</strong></p>
      <ul>
        {completedContents.map((content) => (
          <li key={content.id}>{content.title}</li>
        ))}
      </ul>
    </div>
  );
}

export default UserProfile;
