// src/components/TermsOfService.js
import React from 'react';
import './TermsOfService.css';

function TermsOfService() {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <section>
        <h2>1. Application of Terms</h2>
        <p>These terms and conditions apply to all acts performed by users when using our service.</p>
      </section>
      <section>
        <h2>2. User Registration</h2>
        <p>Users who wish to use this service must register in accordance with the methods prescribed by our company.</p>
      </section>
      {/* Add other sections as needed */}
    </div>
  );
}

export default TermsOfService;
