// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBQle9zDLC7H7iI39gs1z8c8tmD40-E4Jk",
    authDomain: "tadolingual.firebaseapp.com",
    projectId: "tadolingual",
    storageBucket: "tadolingual.appspot.com",
    messagingSenderId: "1076072994476",
    appId: "1:1076072994476:web:ecc0415ababc50549a3109",
    measurementId: "G-0M8LWQJ6QR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Storageの初期化

export { db, auth, storage }; // Storageをエクスポート