import React, { useState } from 'react';
import { db, storage } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './AddContent.css';

function AddContent() {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [author, setAuthor] = useState('');
  const [level, setLevel] = useState('');
  const [genre, setGenre] = useState('');
  const [subGenre, setSubGenre] = useState('');
  const [summary, setSummary] = useState('');
  const [totalWordCount, setTotalWordCount] = useState(0);
  const [imageFile, setImageFile] = useState(null);
  const [status, setStatus] = useState('new');
  const [readingLevels, setReadingLevels] = useState({
    Eiken: '',
    TOEIC: '',
    CEFR: '',
    Lexile: '',
    TOEFL_iBT: '',
    TOEFL_PBT: '',
    IELTS: '',
    Cambridge: ''
  });
  const [tags, setTags] = useState('');
  const [difficultyScore, setDifficultyScore] = useState(0);
  const [rating, setRating] = useState(0);
  const [numRatings, setNumRatings] = useState(0);
  const [audioUrl, setAudioUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [relatedContent, setRelatedContent] = useState('');
  const [readingTime, setReadingTime] = useState(0);
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (imageFile) {
      const storageRef = ref(storage, `images/${imageFile.name}`);
      const uploadTask = uploadBytesResumable(storageRef, imageFile);

      setUploading(true);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // プログレスバーやその他のフィードバックを表示できます
        },
        (error) => {
          console.error('Upload failed:', error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          saveContentToFirestore(downloadURL);
        }
      );
    } else {
      saveContentToFirestore(null);
    }
  };

  const saveContentToFirestore = async (imageUrl) => {
    try {
      await addDoc(collection(db, 'contents'), {
        title,
        text,
        author,
        level,
        genre,
        subGenre,
        summary,
        totalWordCount: parseInt(totalWordCount),
        imageUrl,
        status,
        readingLevels: {
          Eiken: [readingLevels.Eiken],
          TOEIC: [readingLevels.TOEIC],
          CEFR: [readingLevels.CEFR],
          Lexile: [readingLevels.Lexile],
          TOEFL_iBT: [readingLevels.TOEFL_iBT],
          TOEFL_PBT: [readingLevels.TOEFL_PBT],
          IELTS: [readingLevels.IELTS],
          Cambridge: [readingLevels.Cambridge]
        },
        tags: tags.split(',').map(tag => tag.trim()),
        difficultyScore,
        rating,
        numRatings,
        audioUrl,
        videoUrl,
        relatedContent: relatedContent.split(',').map(contentId => contentId.trim()),
        readingTime,
        createdAt: new Date(),
        updatedAt: new Date(),
        publishedDate: new Date()
      });
      alert('Content added successfully!');
      resetForm();
    } catch (error) {
      console.error('Error adding content: ', error);
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setTitle('');
    setText('');
    setAuthor('');
    setLevel('');
    setGenre('');
    setSubGenre('');
    setSummary('');
    setTotalWordCount(0);
    setImageFile(null);
    setStatus('new');
    setReadingLevels({
      Eiken: '',
      TOEIC: '',
      CEFR: '',
      Lexile: '',
      TOEFL_iBT: '',
      TOEFL_PBT: '',
      IELTS: '',
      Cambridge: ''
    });
    setTags('');
    setDifficultyScore(0);
    setRating(0);
    setNumRatings(0);
    setAudioUrl('');
    setVideoUrl('');
    setRelatedContent('');
    setReadingTime(0);
  };

  return (
    <div className="add-content-container">
      <h2>Add New Content</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div>
          <label>Text</label>
          <textarea value={text} onChange={(e) => setText(e.target.value)} required></textarea>
        </div>
        <div>
          <label>Author</label>
          <input type="text" value={author} onChange={(e) => setAuthor(e.target.value)} required />
        </div>
        <div>
          <label>Level</label>
          <input type="text" value={level} onChange={(e) => setLevel(e.target.value)} required />
        </div>
        <div>
          <label>Genre</label>
          <input type="text" value={genre} onChange={(e) => setGenre(e.target.value)} required />
        </div>
        <div>
          <label>Sub Genre</label>
          <input type="text" value={subGenre} onChange={(e) => setSubGenre(e.target.value)} required />
        </div>
        <div>
          <label>Summary</label>
          <textarea value={summary} onChange={(e) => setSummary(e.target.value)} required></textarea>
        </div>
        <div>
          <label>Total Word Count</label>
          <input type="number" value={totalWordCount} onChange={(e) => setTotalWordCount(e.target.value)} required />
        </div>
        <div>
          <label>Image File</label>
          <input type="file" onChange={(e) => setImageFile(e.target.files[0])} />
        </div>
        <div>
          <label>Status</label>
          <input type="text" value={status} onChange={(e) => setStatus(e.target.value)} required />
        </div>
        <div>
          <label>Reading Levels (Eiken)</label>
          <input type="text" value={readingLevels.Eiken} onChange={(e) => setReadingLevels({...readingLevels, Eiken: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (TOEIC)</label>
          <input type="text" value={readingLevels.TOEIC} onChange={(e) => setReadingLevels({...readingLevels, TOEIC: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (CEFR)</label>
          <input type="text" value={readingLevels.CEFR} onChange={(e) => setReadingLevels({...readingLevels, CEFR: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (Lexile)</label>
          <input type="text" value={readingLevels.Lexile} onChange={(e) => setReadingLevels({...readingLevels, Lexile: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (TOEFL iBT)</label>
          <input type="text" value={readingLevels.TOEFL_iBT} onChange={(e) => setReadingLevels({...readingLevels, TOEFL_iBT: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (TOEFL PBT)</label>
          <input type="text" value={readingLevels.TOEFL_PBT} onChange={(e) => setReadingLevels({...readingLevels, TOEFL_PBT: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (IELTS)</label>
          <input type="text" value={readingLevels.IELTS} onChange={(e) => setReadingLevels({...readingLevels, IELTS: e.target.value})} />
        </div>
        <div>
          <label>Reading Levels (Cambridge)</label>
          <input type="text" value={readingLevels.Cambridge} onChange={(e) => setReadingLevels({...readingLevels, Cambridge: e.target.value})} />
        </div>
        <div>
          <label>Tags (comma separated)</label>
          <input type="text" value={tags} onChange={(e) => setTags(e.target.value)} />
        </div>
        <div>
          <label>Difficulty Score</label>
          <input type="number" value={difficultyScore} onChange={(e) => setDifficultyScore(e.target.value)} />
          </div>
        <div>
          <label>Rating</label>
          <input type="number" value={rating} onChange={(e) => setRating(e.target.value)} />
        </div>
        <div>
          <label>Number of Ratings</label>
          <input type="number" value={numRatings} onChange={(e) => setNumRatings(e.target.value)} />
        </div>
        <div>
          <label>Audio URL</label>
          <input type="text" value={audioUrl} onChange={(e) => setAudioUrl(e.target.value)} />
        </div>
        <div>
          <label>Video URL</label>
          <input type="text" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
        </div>
        <div>
          <label>Related Content (comma separated)</label>
          <input type="text" value={relatedContent} onChange={(e) => setRelatedContent(e.target.value)} />
        </div>
        <div>
          <label>Reading Time (minutes)</label>
          <input type="number" value={readingTime} onChange={(e) => setReadingTime(e.target.value)} />
        </div>
        <button type="submit" disabled={uploading}>{uploading ? 'Uploading...' : 'Add Content'}</button>
      </form>
    </div>
  );
}

export default AddContent;
