// src/components/ContentDetail.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import './ContentDetail.css';

function ContentDetail() {
  const { id } = useParams();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'contents', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, [id]);

  const getWeekNumber = (date) => {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  };

  const handleComplete = async () => {
    if (auth.currentUser) {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const timeZone = userData.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
        const today = new Date();
        const todayString = today.toLocaleDateString('en-CA', { timeZone }); // 'en-CA' format for YYYY-MM-DD
        const weekNumber = getWeekNumber(today);
        const month = today.getMonth() + 1; // getMonthは0から始まるので+1する
        const year = today.getFullYear();
        
        const dailyTotalKey = `dailyTotalWordCount.${todayString.replace(/-/g, '_')}`;
        const weeklyTotalKey = `weeklyTotalWordCount.${year}_week${weekNumber}`;
        const monthlyTotalKey = `monthlyTotalWordCount.${year}_month${month}`;

        const updateData = {
          completedContents: arrayUnion(id),
          totalWordCount: increment(content.totalWordCount),
        };

        updateData[dailyTotalKey] = increment(content.totalWordCount);
        updateData[weeklyTotalKey] = increment(content.totalWordCount);
        updateData[monthlyTotalKey] = increment(content.totalWordCount);

        await updateDoc(userDocRef, updateData);

        alert('Marked as complete');
      } else {
        console.log('User document does not exist!');
      }
    } else {
      alert('You need to log in to mark as complete');
    }
  };

  if (!content) {
    return <div>Loading content...</div>;
  }

  return (
    <div className="content-detail-container">
      <h2>{content.title}</h2>
      <img src={content.imageUrl} alt={content.title} className="content-detail-image" />
      <p>{content.text}</p>
      <p><strong>Author:</strong> {content.author}</p>
      <p><strong>Level:</strong> {content.level}</p>
      <p><strong>Word Count:</strong> {content.totalWordCount}</p>
      <button className="button-complete" onClick={handleComplete}>Mark as Complete</button>
    </div>
  );
}

export default ContentDetail;
