// src/components/LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import { FaBookOpen, FaUserGraduate, FaChartLine, FaUserCircle } from 'react-icons/fa'; // Font Awesome アイコンのインポート
import pandaImage from '../assets/images/panda-reading.png'; // イメージキャラクターの画像をインポート

function LandingPage() {
  return (
    <div className="landing-page">
      <section className="hero">
        <div className="hero-content">
          <img src={pandaImage} alt="Panda reading" className="hero-image" />
          <div className="hero-text">
            <h1>英語多読を楽しく続けよう</h1>
            <p>自分に合ったレベルとワード数で多読コンテンツを楽しめます。総単語数を管理し、興味のあるジャンルを楽しく読めるTadolingual。</p>
            <Link to="/register" className="cta-button">今すぐ無料で始める</Link>
          </div>
        </div>
      </section>

      <section className="features">
        <h2>Features</h2>
        <div className="feature-grid">
          <div className="feature">
            <FaBookOpen className="feature-icon" />
            <h3>豊富なリーディング素材</h3>
            <p>様々なレベルやジャンルの素材が揃っており、興味のある分野を見つけて楽しく学べます。</p>
          </div>
          <div className="feature">
            <FaUserGraduate className="feature-icon" />
            <h3>個別の多読学習</h3>
            <p>あなたのレベルに合わせた多読コンテンツで学習ができます。各コンテンツには、理解度に応じた問題や解説が付いています。</p>
          </div>
          <div className="feature">
            <FaChartLine className="feature-icon" />
            <h3>進捗トラッキング</h3>
            <p>多読の進捗を確認し、達成感を得ましょう。読むごとに進捗を記録し、目標達成に向けてモチベーションを保てます。</p>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <h2>利用者の声</h2>
        <div className="testimonial-grid">
          <div className="testimonial">
            <FaUserCircle className="testimonial-icon" />
            <p>"Tadolingualで英語の勉強が楽しくなりました！"</p>
            <span>田中一郎</span>
          </div>
          <div className="testimonial">
            <FaUserCircle className="testimonial-icon" />
            <p>"毎日少しずつ学習できるのが嬉しいです。"</p>
            <span>佐藤花子</span>
          </div>
        </div>
      </section>

      <section className="support">
        <h2>サポート</h2>
        <div className="faq">
          <h3>FAQ</h3>
          <div className="question">
            <h4>Q1: Tadolingualは無料で使えますか？</h4>
            <p>はい、基本機能は無料でお使いいただけます。プレミアムプランもご用意しています。</p>
          </div>
          <div className="question">
            <h4>Q2: どのレベルの学習者に向いていますか？</h4>
            <p>初級者から上級者まで、すべてのレベルの学習者に適しています。</p>
          </div>
          <div className="question">
            <h4>Q3: コンテンツはどのくらいの頻度で更新されますか？</h4>
            <p>毎月新しいコンテンツが追加されます。</p>
          </div>
          <div className="question">
            <h4>Q4: アカウントの削除方法を教えてください。</h4>
            <p>アカウント設定からいつでも削除できます。</p>
          </div>
        </div>

        <div className="contact-form">
          <h3>お問い合わせ</h3>
          <form>
            <label>
              お名前:
              <input type="text" name="name" required />
            </label>
            <label>
              メールアドレス:
              <input type="email" name="email" required />
            </label>
            <label>
              メッセージ:
              <textarea name="message" required></textarea>
            </label>
            <button type="submit">送信</button>
          </form>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
