import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { db, auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from "firebase/auth";
import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';

Chart.register(...registerables);

const ReadingLog = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState({
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM dd',
          displayFormats: {
            day: 'MMM dd'
          }
        },
        adapters: {
          date: {
            locale: enUS
          }
        }
      },
      y: {
        beginAtZero: true
      }
    }
  });

  useEffect(() => {
    const fetchData = async (user) => {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const dailyData = userData.dailyTotalWordCount;

        console.log("User Data: ", userData);
        console.log("Daily Data: ", dailyData);

        if (dailyData) {
          const dates = Object.keys(dailyData).map(dateStr => parseISO(dateStr.replace(/_/g, '-')));
          const wordCounts = Object.values(dailyData);
          const maxWordCount = Math.max(...wordCounts);

          setChartData({
            labels: dates,
            datasets: [
              {
                label: 'Words Read',
                data: wordCounts,
                fill: false,
                borderColor: 'orange',
                tension: 0.1
              }
            ]
          });

          // Y軸の最大値を1.5倍に設定
          const maxY = maxWordCount * 1.5;

          setChartOptions(prevOptions => ({
            ...prevOptions,
            scales: {
              ...prevOptions.scales,
              y: {
                ...prevOptions.scales.y,
                max: maxY // Y軸の最大値を設定
              }
            }
          }));
        } else {
          setChartData({
            labels: [],
            datasets: [
              {
                label: 'Words Read',
                data: [],
                fill: false,
                borderColor: 'orange',
                tension: 0.1
              }
            ]
          });
        }
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData(user);
      } else {
        console.log("No current user!");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Reading Log</h2>
      {chartData ? (
        <Line
          data={chartData}
          options={chartOptions}
        />
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default ReadingLog;
